import React from 'react'

const HighlightText = ({ text, textArray, data }) => {

  let highlightedText=data

  if(textArray&&textArray.length){
    textArray.forEach(element => {
      highlightedText = highlightedText.replace(
        element,
        `<span class="highlight">${element}</span>`
      )
    });

  }

  if(text){
    highlightedText = data.replace(
      text,
      `<span class="highlight">${text}</span>`
    )
  }

  console.log(highlightedText);

    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
  }
  
export default HighlightText