import React from "react"

import Blog from "../Common/Blog"
import ClientBox from "../Common/ClientBox"
import Solutions from "../Common/Solutions"
import Testimonials from "../Common/Testimonials"
import ClientSide from "./ClientSide"
import WdModel from "./WdModel"
import Banner from "./Banner"
import Activities from "../Common/Activities"
import { Helmet } from "react-helmet"

const HomePage = () => {
  return (
    <>
     <Helmet>
        <script
          src="https://kit.fontawesome.com/591c04d670.js"
          crossorigin="anonymous"
        ></script>
       
      </Helmet>
      <Banner />
      <WdModel />
      <ClientSide />
      <Solutions />
      <ClientBox />
      <Activities/>
      <Testimonials />
      <Blog />
    </>
  )
}

export default HomePage
