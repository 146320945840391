import React, { useState } from "react"
import { useSelector } from "react-redux"
import Modal from "react-bootstrap/Modal"

import CommonModal from "../Common/Modal"
import { useEffect } from "react"
import HighlightText from "../Common/HighlightText"

const Banner = () => {
  const language = useSelector(state => state.global.language)
  const banner = useSelector(
    state => state?.homepage?.page?.homepage_contents?.banner
  )

  const [currentIndex, setIndex] = useState(0)
  const words = banner.titleGroups.map(group => group.text)


  useEffect(() => {
    let interval = null

    if (interval) {
      clearInterval(interval)
    }
    interval = setInterval(() => {
      setIndex(currentIndex => currentIndex >= words.length - 1 ? 0 : currentIndex + 1)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const [modal, setModal] = useState(false)
  const closeModal = () => {
    setModal(false)
  }


  const [first, second] = banner?.title?.[language].split('TALENTS')


  return (
    <div className="banner homebanner">
      <div className="home_banner_container">
        <div className="banner_top"></div>

        <div className="banner_text_wrap">
          <div className="banner_txt">
            <div>
              <div className="light img-fluid">
                <img
                  src={banner.image.sourceUrl}
                  alt={banner.image.title ? banner.image.title : "Home-Avatar"}
                />
              </div>
              <h1 className="split-text landing-heading">
                <span key={currentIndex} className="">{first}</span>
                <span key={`sub${currentIndex}`} className="split-text-word split-text-word-red" >{words[currentIndex]}</span>
                <span className="talents">{second}</span>
              </h1>
              <p className="split-text">
                {/* <span className="banner_txtclr">
                  {banner?.highlightedText?.[language]}
                </span> */}
                <HighlightText textArray={["AI", "Webdura"]}data={`${banner?.highlightedText?.[language]} ${banner?.text?.[language]}`} />

              </p>
              <button
                type="button"
                onClick={() => setModal(true)}
                data-toggle="modal"
                data-target="#basicModal1"
                className="common_cls banhide"
              >
                {banner?.buttonText?.[language]}
                <i className="arrow" />
              </button>
            </div>
            <div>
              <button
                type="button"
                onClick={() => setModal(true)}
                href="#"
                data-toggle="modal"
                data-target="#basicModal1"
                className="common_cls ban"
              >
                {banner?.buttonText?.[language]}
                <i className="arrow" />
              </button>
            </div>
          </div>
          {/* <div className="home_banner_video_wrap">

            <div className="home_banner_video_quote_wrap">

              <div className="quote-icon">
                <div />
              </div>

              <div className="quote-text">
              Meet our trailblazers
              </div>

            </div>


            <video
              muted
              id="myVideo"
              controls
              autoPlay
              src={"https://webdurawdfeed.s3.ap-south-1.amazonaws.com/imgtech/2023/03/06070617/wd_intro_32mb.mp4"}
              type="video/mp4"
            ></video>

          </div> */}
        </div>

      </div>

      <Modal
        scrollable={true}
        show={modal}
        centered
        onHide={() => closeModal()}
      >
        <CommonModal closeModal={closeModal} />
      </Modal>
    </div>
  )
}
export default Banner
