import React, { useState } from "react"
import { useSelector } from "react-redux"
import Modal from "react-bootstrap/Modal"

import CommonModal from "../Common/Modal"
import HighlightText from "../Common/HighlightText"

const WdModel = () => {
  const language = useSelector(state => state.global.language)
  const data = useSelector(
    state => state?.homepage?.page?.homepage_contents?.specialTeam
  )

  const [modal, setModal] = useState(false)
  const closeModal = () => {
    setModal(false)
  }

  return (
    <>
      <section>
        <div
          style={{
            backgroundColor: "#111",
            paddingTop: 60,
            position: "relative",
            paddingBottom: 100,
          }}
        >
          <div className="phoneclr p-0">
            <div className="row text_side">
              <div className="txt_block" style={{ color: "#fff" }}>
                <div className="lftbrdrn">
                  <h6 style={{ textTransform: "uppercase" }}>
                    {data?.subtitle?.[language]}
                  </h6>
                </div>
                <h2 style={{ color: "#fff" }}>
                  <HighlightText text="Ai" data={data?.mainTitle?.[language]} />
                </h2>
                <p style={{ color: "#fff", fontSize: 15 }}>
                  {data?.mainDescription?.[language]}
                </p>
              </div>
            </div>
          </div>
          <div className="team-white-container">
            <div className="txt_block lftbrdr">
              <div style={{ marginRight: 20 }}>
                <h3 className="team-heading">{data?.title?.[language]}</h3>
                <p className="team-paragraph" style={{ color: "#111" }}>
                  {data?.description?.[language]}
                </p>
                <button
                  type="button"
                  onClick={() => setModal(true)}
                  className="common_cls_new"
                  style={{ marginTop: 30 }}
                >
                  {data?.buttontext?.[language]}
                  <i className="arrow" />
                </button>
              </div>
            </div>
            <div className="para_block" style={{ paddingLeft: 40 }}>
              <p className="team-question">{data?.question?.[language]}</p>
              <ul style={{ marginLeft: 0 }}>
                {data?.solutions?.answer.map((ele, index) => (
                  <li
                    style={{ display: "flex", lineHeight: "20px" }}
                    key={`${ele[language]}${index}`}
                  >
                    <i
                      className="bi bi-check-lg"
                      style={{
                        color: "#ef4035",
                        marginRight: 10,
                        fontSize: 20,
                      }}
                    />
                    <p className="team-answers">{ele[language]}</p>
                  </li>
                ))}
              </ul>
              <h4
                style={{
                  marginTop: 50,
                  letterSpacing: "-2px",
                  fontSize: "19px",
                  fontFamily: "Poppins",
                }}
              >
                {data?.clientTitle[language]}
              </h4>
              <ul
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: 0,
                }}
                className="justify_center"
              >
                {data?.clients.map(ele => (
                  <li style={{ marginRight: 25 }}>
                    <img
                      className="client-image"
                      width={100}
                      src={ele.img.sourceUrl}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Modal
        scrollable={true}
        show={modal}
        centered
        onHide={() => closeModal()}
      >
        <CommonModal closeModal={closeModal} />
      </Modal>
    </>
  )
}

export default WdModel
